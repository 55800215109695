import { Category, Language, Websites } from "../../common/constants/movies";
import ClientRequest from "../../setup/client/api-client";
import { SET_ENGLISH_MOVIES, SET_HINDI_MOVIES, SET_REQUESTED, SET_TELUGU_MOVIES, UPDATE_CURRENT_LIST_PAGE_ITEMS } from "../types/movies";

export const GetContentCall = (language: string) => async (dispatch: (arg0: {}) => any, getState: any) => {
  const Movies = await ClientRequest({
    url: `/videos/${language}/list?category=${Category.movie}`,
    method: "get",
  });
  const RealityShows = await ClientRequest({
    url: `/videos/${language}/list?category=${Category.reality_shows}`,
    method: "get",
  });
  const WebSeries = await ClientRequest({
    url: `/videos/${language}/list?category=${Category.web_series}`,
    method: "get",
  });
  const Channels = await ClientRequest({
    url: `/videos/${language}/list?category=${Category.channel}`,
    method: "get",
  });

  let dataType = "";
  if (language === Language.english) {
    dataType = SET_ENGLISH_MOVIES;
  } else if (language === Language.hindi) {
    dataType = SET_HINDI_MOVIES;
  } else {
    dataType = SET_TELUGU_MOVIES;
  }

  await dispatch({
    type: dataType,
    data: {
      [Category.movie]: Movies.data.data,
      [Category.reality_shows]: RealityShows.data.data,
      [Category.web_series]: WebSeries.data.data,
      [Category.channel]: Channels.data.data,
    },
  });

  const Requested = await ClientRequest({
    url: `/videos/${Category.requested}/list?category=${Category.requested}`,
    method: "get",
  });

  return await dispatch({
    type: SET_REQUESTED,
    data: Requested.data.data,
  });
};

export const LoadNewMovies = () => async (dispatch: (arg0: {}) => any, getState: any) => {
  await Promise.all([
    ClientRequest({
      url: `/videos/load?language=telugu&website=${Websites.movieRulz}`,
      method: "get",
    }),
    ClientRequest({
      url: `/videos/load?language=telugu&website=${Websites.bolly2tolly}`,
      method: "get",
    }),
  ]);
};

export const GetMoviesListCall =
  (language: string, category: string, page = 1, limit = 20) =>
  async (dispatch: (arg0: {}) => any, getState: any) => {
    let response = await ClientRequest({
      url: `/videos/${language}/list?limit=${limit}&page=${page}&category=${category}`,
      method: "get",
    });
    return await dispatch({
      type: UPDATE_CURRENT_LIST_PAGE_ITEMS,
      data: { data: response.data.data, language, category },
    });
  };
