import React, { Suspense, useEffect } from 'react';
import Router from "./setup/routes-manager"
import { connect, useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Loading from './common/components/loading';
import { DEFAULT_TOASTER } from './common/constants';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import ErrorBoundary from './common/components/error-boundery';
import { START_LOADER, STOP_LOADER } from './store/types/loader';
import { SET_DATE } from './store/types/movies';
import { Language } from './common/constants/movies';
import { GetContentCall, LoadNewMovies } from './store/actions/movies';

function App(props: any) {
  const dispatch = useDispatch()

  const callOnLoad = async () => {
    try {
      dispatch({ type: START_LOADER })
      await Promise.all([
        props.GetContentCall(Language.telugu),
      ])
    } finally {
      dispatch({ type: STOP_LOADER })
    }
  }

  useEffect(() => {
    if (props.latestCallTime) {

      const OneDay = new Date(props.latestCallTime).setHours(0, 0, 0, 0) + (1 * 24 * 60 * 60 * 1000)
      if (OneDay < new Date().getTime()) {
        props.LoadNewMovies().then(() => {
          callOnLoad()
          dispatch({ type: SET_DATE })
        })
      }
    }

    if (!props.latestCallTime) {
      dispatch({ type: SET_DATE })
    }

    callOnLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Loading />
        <ToastContainer {...DEFAULT_TOASTER} />
        <Suspense fallback={<Loading />}>
          <Router />
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default connect(
  (state: any, ownProps: any) => ({
    ...ownProps,
    ...state.videos
  }),
  {
    LoadNewMovies,
    GetContentCall,
  }
)(App);

