export const SET_TELUGU_MOVIES = "SET_TELUGU_MOVIES";
export const SET_HINDI_MOVIES = "SET_HINDI_MOVIES";
export const SET_ENGLISH_MOVIES = "SET_ENGLISH_MOVIES";
export const SET_WATCHLIST = "SET_WATCHLIST";
export const SET_WEBSITES = "SET_WEBSITES";
export const SET_REQUESTED = "SET_REQUESTED";
export const DELETE_WATCH_LIST_MOVIE = "DELETE_WATCH_LIST_MOVIE";


export const SET_DATE = "SET_DATE";

export const SET_INITIAL_DATA = "SET_INITIAL_DATA";

export const UPDATE_CURRENT_LIST_PAGE_ITEMS = "UPDATE_CURRENT_LIST_PAGE_ITEMS";


