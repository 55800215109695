import { Category, Language } from "../../common/constants/movies";
import {
  DELETE_WATCH_LIST_MOVIE,
  SET_DATE,
  SET_ENGLISH_MOVIES,
  SET_HINDI_MOVIES,
  SET_INITIAL_DATA,
  SET_REQUESTED,
  SET_TELUGU_MOVIES,
  SET_WATCHLIST,
  SET_WEBSITES,
  UPDATE_CURRENT_LIST_PAGE_ITEMS,
} from "../types/movies";

const initState = {
  websites: [],
  watchList: [],
  requested: [],
  [Language.telugu]: {
    [Category.movie]: [],
    [Category.reality_shows]: [],
    [Category.web_series]: [],
    [Category.channel]: [],
  },
  [Language.hindi]: {
    [Category.movie]: [],
    [Category.reality_shows]: [],
    [Category.web_series]: [],
    [Category.channel]: [],
  },
  [Language.english]: {
    [Category.movie]: [],
    [Category.reality_shows]: [],
    [Category.web_series]: [],
    [Category.channel]: [],
  },
  latestCallTime: null,
};

const reducer = (state = initState, action: { type: string; data: any }) => {
  switch (action.type) {
    case SET_INITIAL_DATA: {
      return initState;
    }

    case SET_TELUGU_MOVIES: {
      return {
        ...state,
        [Language.telugu]: action.data,
      };
    }

    case DELETE_WATCH_LIST_MOVIE: {
      return {
        ...state,
        watchList: state.watchList.filter((data: any) => data._id !== action.data),
      };
    }

    case SET_HINDI_MOVIES: {
      return {
        ...state,
        [Language.hindi]: action.data,
      };
    }

    case SET_ENGLISH_MOVIES: {
      return {
        ...state,
        [Language.english]: action.data,
      };
    }

    case SET_REQUESTED: {
      return {
        ...state,
        requested: action.data,
      };
    }

    case SET_WEBSITES: {
      return {
        ...state,
        websites: action.data,
      };
    }

    case SET_DATE: {
      return {
        ...state,
        latestCallTime: new Date().setHours(0, 0, 0, 0),
      };
    }

    case SET_WATCHLIST: {
      return {
        ...state,
        watchList: action.data,
      };
    }

    case UPDATE_CURRENT_LIST_PAGE_ITEMS: {
      return {
        ...state,
        [action.data.language]: {
          ...(state as any)[action.data.language],
          [action.data.category]: [...(state as any)[action.data.language][action.data.category], ...action.data.data].filter(
            (v, i, a) => a.findIndex((v2) => v._id === v2._id) === i
          ),
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
