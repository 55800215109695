import React from 'react'

function VideoIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50">
            <path d="M 2 4 A 1.0001 1.0001 0 0 0 1 5 L 1 9.8320312 A 1.0001 1.0001 0 0 0 1 10.158203 L 1 39.832031 A 1.0001 1.0001 0 0 0 1 40.158203 L 1 45 A 1.0001 1.0001 0 0 0 2 46 L 48 46 A 1.0001 1.0001 0 0 0 49 45 L 49 40.167969 A 1.0001 1.0001 0 0 0 49 39.841797 L 49 10.167969 A 1.0001 1.0001 0 0 0 49 9.8417969 L 49 5 A 1.0001 1.0001 0 0 0 48 4 L 2 4 z M 3 6 L 6 6 L 6 9 L 3 9 L 3 6 z M 8 6 L 12 6 L 12 9 L 8 9 L 8 6 z M 14 6 L 18 6 L 18 9 L 14 9 L 14 6 z M 20 6 L 24 6 L 24 9 L 20 9 L 20 6 z M 26 6 L 30 6 L 30 9 L 26 9 L 26 6 z M 32 6 L 36 6 L 36 9 L 32 9 L 32 6 z M 38 6 L 42 6 L 42 9 L 38 9 L 38 6 z M 44 6 L 47 6 L 47 9 L 44 9 L 44 6 z M 3 11 L 47 11 L 47 39 L 3 39 L 3 11 z M 20.941406 18 A 1.0001 1.0001 0 0 0 20 19 L 20 31 A 1.0001 1.0001 0 0 0 21.513672 31.857422 L 31.513672 25.857422 A 1.0001 1.0001 0 0 0 31.513672 24.142578 L 21.513672 18.142578 A 1.0001 1.0001 0 0 0 21.041016 18 A 1.0001 1.0001 0 0 0 20.941406 18 z M 22 20.765625 L 29.056641 25 L 22 29.234375 L 22 20.765625 z M 3 41 L 6 41 L 6 44 L 3 44 L 3 41 z M 8 41 L 12 41 L 12 44 L 8 44 L 8 41 z M 14 41 L 18 41 L 18 44 L 14 44 L 14 41 z M 20 41 L 24 41 L 24 44 L 20 44 L 20 41 z M 26 41 L 30 41 L 30 44 L 26 44 L 26 41 z M 32 41 L 36 41 L 36 44 L 32 44 L 32 41 z M 38 41 L 42 41 L 42 44 L 38 44 L 38 41 z M 44 41 L 47 41 L 47 44 L 44 44 L 44 41 z\" />
        </svg>
    )
}

export default VideoIcon