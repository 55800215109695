import React from "react";
import "./navbar.css";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadIcon from "../../../assets/svg/loadIcon";
import { LoadNewMovies } from "../../../store/actions/movies";
import RequestUrlIcon from "../../../assets/svg/RequestUrlIcon";
import loveAnimation from "../../../assets/gif/icons8-love.gif";
import VideoIcon from "../../../assets/svg/VideoIcon";
import SearchUI from "./Search";

export default function NavBar() {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const hideNavBar = useSelector((state: any) => state.loader.hideNavBar);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLoadMovies = () => {
    setMobileMenuOpen(false)
    dispatch(LoadNewMovies() as any);
  };

  const handleSelectNavOption = (url: string) => {
    setMobileMenuOpen(false)
    navigate(url)
  };

  if (hideNavBar) return <Outlet />;

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light removePadding">
        <span className="navbar-brand logo" onClick={() => navigate("/")}>
          DJ <img className="gif-logo" src={loveAnimation} alt="❤️" /> Movies
        </span>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${mobileMenuOpen ? "show" : ""}`} id="navbarNav">
          <ul className="navbar-nav" style={mobileMenuOpen ? {} : { marginLeft: "auto", marginRight: "10px" }}>
            <li className="nav-item active">
              <button
                style={{ marginRight: "5px" }}
                type="button"
                className={`btn ${mobileMenuOpen ? "" : "btn-link"}`}
                onClick={() => handleSelectNavOption("/video-player")}
              >
                <div style={{ display: "flex" }}>
                  <VideoIcon /> {mobileMenuOpen ? <p>&nbsp; Video Player</p> : null}
                </div>
              </button>
            </li>
            <li className="nav-item">
              <button
                style={{ marginRight: "5px" }}
                type="button"
                className={`btn ${mobileMenuOpen ? "" : "btn-link"}`}
                onClick={() => handleSelectNavOption("/request-url")}
              >
                <div style={{ display: "flex" }}>
                  <RequestUrlIcon /> {mobileMenuOpen ? <p>&nbsp; Request URL</p> : null}
                </div>
              </button>
            </li>
            <li className="nav-item">
              <button
                style={{ marginRight: "5px" }}
                type="button"
                className={`btn ${mobileMenuOpen ? "" : "btn-link"}`}
                onClick={handleLoadMovies}>
                <div style={{ display: "flex" }}>
                  <LoadIcon /> {mobileMenuOpen ? <p>&nbsp; Reload New Movies</p> : null}
                </div>
              </button>
            </li>
            <li className="nav-item mb-2 mb-md-0">
              <SearchUI />
            </li>
          </ul>
        </div>
      </nav>
      <Outlet />
    </>
  );
}
