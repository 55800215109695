import React from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from "../../common/components/navbar";

// PAGES
const HomePage = React.lazy(() => import("../../Pages/homePage"));
const VideoPlayerPage = React.lazy(() => import("../../Pages/VideoPlayerPage"));
const PageNotFoundPage = React.lazy(() => import("../../Pages/pageNotFound"));
const PictureListPage = React.lazy(() => import("../../Pages/pictureListPage"));
const VideoPlayer = React.lazy(() => import("../../Pages/VideoPlayer"));
const RequestUrl = React.lazy(() => import("../../Pages/RequestUrl"));


function RootRouter() {
  return (
    <Routes>
      <Route element={<NavBar />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/:category/list" element={<PictureListPage />} />
        <Route path="/request-url" element={<RequestUrl />} />
        <Route path="*" element={<PageNotFoundPage />} />
      </Route>
      <Route path="/video" element={<VideoPlayerPage />} />
      <Route path="/video-player" element={<VideoPlayer />} />
    </Routes>
  );
}

export default RootRouter;
