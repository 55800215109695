import React, { useState } from 'react';
import SearchIcon from '../../../assets/svg/SearchIcon';
import ClientRequest from '../../../setup/client/api-client';
import { Category, Language } from '../../constants/movies';
import { useNavigate } from 'react-router-dom';

function SearchUI() {
    const navigate = useNavigate()
    const [options, setOptions] = useState([]);
    const [str, setStr] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState<any>(null);

    const handleInputChange = (event: any) => {
        const inputStr = event.target.value;
        setStr(inputStr);
        setShowDropdown(inputStr.length > 0);

        // Clear the previous timeout
        clearTimeout(typingTimeout);

        // Set a new timeout
        const newTypingTimeout = setTimeout(() => {
            // Invoke the Axios GET API after the delay
            inputStr && inputStr.trim() !== "" && fetchData(inputStr);
        }, 1000);

        setTypingTimeout(newTypingTimeout);
    };

    const fetchData = async (searchQuery: any) => {
        try {
            const response = await ClientRequest({
                url: `videos/search_local/list?name=${searchQuery}&limit=5`,
                method: "get",
            });
            setOptions(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSelectOption = (selectedOption: any) => {
        navigate(`/video?language=${selectedOption.language || Language.telugu}&category=${selectedOption.category || Category.movie}&id=${selectedOption._id}`)
        setStr(selectedOption.name);
        setShowDropdown(false);
    };

    return (
        <div className="position-relative">
            <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback mr-2">
                    <SearchIcon />
                </span>
                <input
                    type="text"
                    className="form-control border-end-0 border rounded-pill"
                    placeholder="Search"
                    onChange={handleInputChange}
                    value={str}
                />
            </div>
            {showDropdown && (
                <div className="dropdown position-absolute w-100">
                    <ul className="list-group">
                        {options.map((option: any, index) => (
                            <li
                                key={index}
                                className="list-group-item"
                                onClick={() => handleSelectOption(option)}
                            >
                                {option.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default SearchUI;
