export enum Category {
  channel = "channel",
  movie = "movie",
  reality_shows = "reality_show",
  web_series = "web_serie",
  watchList = "watchList",
  requested = "requested",
}

export enum Language {
  telugu = "telugu",
  hindi = "hindi",
  english = "english",
}

export enum Types {
  video = "video",
  iframe = "iframe",
  script = "script",
  m3u8 = "m3u8",
  torrent = "torrent",
}

export enum Websites {
  movieRulz = "movieRulz",
  bolly2tolly = "bolly2tolly",
  local = "local",
}
